import './App.css';
import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  const submit= (e) => {
    e.preventDefault();

    const formData = new FormData(document.querySelector('form'));
    fetch("https://script.google.com/macros/s/AKfycbz7s5_m7HdrqqoKT8fDs3uDnfdLKBQlfzvpG7ZKc6Q0Kmst9gfFVZfT7JPzFsRP0onC/exec", { 
      method: 'POST',
      body: formData
    })
    toast.success('register success');
    document.getElementById("myForm").reset();

  }
  return (
    <>
      <h1>Register</h1>
      <form id='myForm' className='form' onSubmit={ submit }>
        <input required placeholder='Team Name' name='Team' type="text" />
        <input required placeholder='Game' name='Game' type="text" />
        <input required placeholder='Phone' name='Phone' type="number" />
        <input required placeholder='Email' name='Email' type="email" />
        <input className='button' type='submit' />
      </form>
    </>
  )
}

export default App
